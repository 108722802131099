<template>
  <CreateBillSettlementComp />
</template>
<script>
export default {
  name: "CreateSettlement",
  components: {
    CreateBillSettlementComp: () => import("../../../src/components/bill_settlements/CreateBillSettlementComp.vue"),
  },
  data() {
    return {
      breadcrumbs: [{text:"Home", path:"/dashboard", isActive: false}, {text:"Settlements", path:"/bills/settlements", isActive: false}, {text:"Create", path:"", isActive: true}]
    }
  },
  created() {
    this.$store.dispatch("Config/setBreadCrumb", this.breadcrumbs);
  },
};
</script>
